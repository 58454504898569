import BaseModel from 'Models/BaseModel';

export function normalize(url) {
  if (url.match(/(https?:)?\/\//)) {
    return url;
  } else if (url.match(/^\/api\/product_images\/\d+$/)) {
    return null;
  } else {
    return `//${url}`;
  }
}

/**
 * @property {string} url
 * @property {string} thumbnailUrl
 * @property {boolean} default
 * @property {string} createdAt
 */
export default class Image extends BaseModel {
  get src() {
    return normalize(this.url);
  }
  get thumb() {
    return normalize(this.thumbnailUrl);
  }
  toString() {
    return this.src;
  }
}
