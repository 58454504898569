import Api from 'Lib/Api';
import { HttpError } from 'Lib/Throwable';
import { Page, Product, Order } from 'Models';

export default class ApiMapper {
  /**
   * @type {Api}
   */
  #api;
  #slugger;

  constructor(api, slugger) {
    if (!(api instanceof Api)) {
      throw new Error('"api" must be instance of Api');
    }
    this.#api = api;
    this.#slugger = slugger;
  }

  /**
   * Returns products collection
   * @param {object} params
   * @param {boolean} usePublicUrl
   * @param {number} [params.itemsPerPage]
   * @param {number} [params.page]
   * @param {(number|number[])} [params.id]
   * @param {string} [params.name]
   * @param {(string|string[])} [params.brand]
   * @returns {Promise<Collection|*>}
   */
  async getProductsCollection(params = {}, usePublicUrl = false) {
    const pub = usePublicUrl ? '/no_auth' : '';
    return this.#api
      .getItemsCollection(`/api/products${pub}`, {
        model: Product,
        options: {
          params: {
            'order[appeared]': 'desc',
            ...(params || {}),
            draft: false,
          },
          re: true,
          ld: true,
        },
      })
      .then(collection => {
        this.#slugger.batchUpdate(collection.items.map(item => [item.slug, item.id]));
        return collection;
      });
  }

  async getProduct(id, usePublicUrl = false) {
    const pub = usePublicUrl ? '/no_auth' : '';
    return this.#api.getItem(`/api/products${pub}/${id}`, { model: Product, options: { re: true } });
  }

  async getProductBySlug(slug, usePublicUrl = false) {
    if (!this.#slugger.has(slug)) {
      const pub = usePublicUrl ? '/no_auth' : '';
      const params = {
        slug,
        itemsPerPage: 1,
        properties: ['id'],
      };
      const [data] = await this.#api.getItem(`/api/products${pub}`, { options: { params, re: true } });
      if (data?.id) {
        this.#slugger.update(slug, data.id);
      } else {
        throw new HttpError('Not found');
      }
    }
    return this.getProduct(this.#slugger.get(slug), usePublicUrl);
  }

  async getOrdersCollection(params = {}) {
    return this.#api.getItemsCollection('/api/vaporfly_orders', {
      model: Order,
      options: {
        ld: true,
        params: {
          'order[createdAt]': 'desc',
          ...(params || {}),
        },
      },
    });
  }

  async getOrderStatuses(params = {}) {
    return this.#api.getItemsCollection('/api/order_statuses', {
      options: {
        params: {
          useInProductOrder: true,
          ...(params || {}),
        },
      },
    });
  }

  async getOrder(id) {
    return this.#api.getItem(`/api/vaporfly_orders/${id}`, { model: Order, options: { re: true } });
  }

  async getUserProfile() {
    return this.#api.getItem('/api/users/profile', { options: { re: true } });
  }

  async placeOrder(data) {
    const { comment } = data;
    const items = data.items.map(({ product, quantity, constructorPool }) => ({
      product: product.toString(),
      quantity: parseInt(quantity),
      constructorPool: constructorPool ? constructorPool.toString() : null,
    }));
    return this.#api
      .post('/api/vaporfly_orders', {
        re: true,
        data: {
          comment,
          items,
        },
      })
      .then(res => res.data);
  }

  async getProductsAmount() {
    const data = await this.#api
      .get('/api/products/vaporfly/total_items', { options: { pub: true } })
      .then(res => res.data);
    return data?.total || 0;
  }

  async updateProfile(data = {}) {
    const res = await this.#api.patch('/api/users/profile', { data });
    if (res.status === 400) {
      throw new Error('Invalid input');
    }
    if (res.status === 404) {
      throw new Error('Resource not found');
    }
    return res.data;
  }

  async getPagesCollection(params = {}) {
    return this.#api.getItemsList('/api/vaporfly_pages', {
      model: Page,
      options: {
        params: {
          ...(params || {}),
        },
      },
    });
  }

  async getPage(slug) {
    return this.#api.getItem(`/api/vaporfly_pages/${slug}`, { model: Page });
  }

  /**
   * @param data {Object}
   * @param data.product {String|Number}
   * @param data.templates {Array}
   * @returns {Promise<{constructors: Array, product: String, pool_id: Number}>}
   */
  async saveProductDesign(data) {
    const { product, templates } = data;
    const { id: pool_id, product: pool_product } = await this.#api
      .post('/api/vaporfly_constructor_pools', { data: { product: product.toString() } })
      .then(res => {
        if (res.status === 201) {
          return res.data;
        } else {
          throw new HttpError('Error', res.status, res);
        }
      });
    const url = `/api/vaporfly_constructors?pool_id=${pool_id}`;
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    const constructors = await Promise.all(
      templates.map(({ file, jsonData }) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('jsonData', JSON.stringify([jsonData]));
        return this.#api.post(url, { headers, data: formData }).then(res => {
          if (res.status === 201) {
            return res.data;
          } else {
            throw new HttpError('Error', res.status, res);
          }
        });
      })
    );
    return { constructors, pool_id, product, pool_product };
  }

  async saveCartDesigns(items) {
    return Promise.all(items.map(data => this.saveProductDesign(data))).then(pools => {
      return Object.fromEntries(pools.map(item => [item.product, item]));
    });
  }

  async sendContactForm(data) {
    return this.#api.post('/api/vaporfly_contact_forms', { data, re: true }).then(res => {
      if (res.status === 201) {
        return res.data;
      } else {
        throw new HttpError('Error occurred', res.status, res);
      }
    });
  }
}
