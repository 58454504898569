/* eslint-disable no-unused-vars */
import Vue from 'vue';
import App from './App.vue';
import Axios from 'axios';
import { default as Vuedals } from 'vuedals';
import './registerServiceWorker';
import router from './router';
import store from './store';
import Api from 'Lib/Api';
import ApiMapper from 'Lib/ApiMapper';
import Slugger from 'Lib/Slugger';
import config from '../project.config';
import VueKonva from 'vue-konva';

Vue.use(VueKonva);

import 'Assets/css/flexboxgrid.css';
import 'Assets/css/styles.css';
import 'Assets/css/extra.scss';
import Pages, { PAGES_KEY } from 'Lib/Pages';
import { restore } from 'Lib/Utils';

Vue.use(Vuedals);
Vue.prototype.$http = Axios;

const slugsMap = new Slugger();
const apiClient = new Api(config.api, store);
Vue.prototype.$vfPages = new Pages(restore(PAGES_KEY, [], true));
Vue.prototype.$slugger = slugsMap;
Vue.prototype.$client = apiClient;
Vue.prototype.$api = new ApiMapper(apiClient, slugsMap);

new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    // You'll need this for renderAfterDocumentEvent.
    document.dispatchEvent(new Event('render-event'));
  },
}).$mount('#app');
