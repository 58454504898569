export const FP_TYPES_AND = 'types';
export const FP_TYPES_OR = 'types.id';
export const FP_TYPES_NOT = 'not_types';

export const VAPORIZERS = {
  categories: [
    {
      id: 87,
      name: '510 Cartridges',
      slug: '510-cartridges',
      filter: {
        types: [87],
      },
    },
    {
      id: 132,
      name: '510 Batteries',
      slug: '510-batteries',
      filter: {
        types: [132],
      },
    },
    {
      id: 81,
      name: 'Disposable pod systems',
      slug: 'disposable-pod-systems',
      filter: {
        types: [81],
      },
    },
    {
      id: -1,
      name: 'Disposable pens',
      slug: 'disposable-pens',
      filter: {
        types: [4, 18],
        not_types: [81],
        features: JSON.stringify([{ 15: ['28'] }]),
      },
    },
    {
      id: -2,
      name: 'Pod systems',
      slug: 'pod-systems',
      filter: {
        types: [4, 32],
        'types.id': [76, 133],
        not_types: [87, 132],
      },
    },
  ],
};

export const PACK_N_ACC = {
  categories: [
    {
      id: 66,
      name: 'Bags',
      slug: 'bags',
    },
    {
      id: 135,
      name: 'Containers',
      slug: 'containers',
    },
  ],
};
