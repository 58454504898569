const STORAGE_KEY = 'vaporfly-slugs-cache';

async function persist(data) {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(data || []));
}

export default class Slugger {
  #map = new Map();
  #rMap = new Map();
  constructor() {
    const json = localStorage.getItem(STORAGE_KEY) || '[]';
    try {
      const entries = JSON.parse(json);
      this.#map = new Map(entries);
      this.#rMap = new Map(entries.map(([slug, id]) => [id, slug]));
    } catch (e) {
      console.error(e);
    }
  }
  update(slug, id) {
    if (slug) {
      this.#map.set(slug, +id);
      this.#rMap.set(+id, slug);
      persist([...this.#map.entries()]).then();
    }
    return this;
  }
  batchUpdate(entries) {
    if (Array.isArray(entries) && entries.length) {
      for (const [slug, id] of entries) {
        this.#map.set(slug, +id);
        this.#rMap.set(+id, slug);
      }
      persist([...this.#map.entries()]).then();
    }
  }
  get(slug) {
    return this.#map.get(slug);
  }
  has(slug) {
    return this.#map.has(slug);
  }
  getSlug(id) {
    return this.#rMap.get(+id);
  }
  hasId(id) {
    return this.#rMap.has(id);
  }
  get size() {
    return this.#map.size;
  }
}
