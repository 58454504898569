<template>
  <div>
    <h3 class="text-center" style="letter-spacing: 2px; font-weight: 500">
      DO YOU HAVE A VALID BUSINESS LICENSE & ARE YOU 21?
    </h3>
    <div class="row text-center">
      <div class="col-md-10 col-md-offset-1 mb-5">
        This site is intended for business-to-business use only and all authorized users shall maintain applicable
        licenses and permits to purchase any products or services through this site.
      </div>
      <div class="col-md-10 col-md-offset-1 mb-5">
        You must verify that you are 21 years of age or older to enter this site.
      </div>

      <div class="col-xs-12 col-md-10 col-md-offset-1">
        <div class="row">
          <div class=" col-xs-12 col-lg-4">
            <div
              class="rounded-5 medium-font button bg-light-green xs-w100  text-medium text-color-white"
              @click="confirmAge(true)"
              style="width: 200px"
            >
              Yes
            </div>
          </div>
          <div class="col-xs-12 col-lg-4 col-lg-offset-4">
            <div
              @click="confirmAge(false)"
              class="rounded-5 text-medium medium-font button xs-mt-15 xs-w100 bg-gray text-medium"
              style="width: 200px"
            >
              No
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AgeConfirmation',
  props: {
    confirmAge: Function,
  },
};
</script>
