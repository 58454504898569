<template>
  <div class="auth-buttons-container">
    <div class="auth-row">
      <div
        class="auth-button block button bg-light-green text-color-white text-bold rounded-5 uppercase bordered-light p-25"
        @click="goto('/login')"
      >
        <span class="block text-bold text-dark">Log In</span>
      </div>
      <div
        class="auth-button block button bg-light-green text-color-white text-bold rounded-5 uppercase bordered-light p-25"
        @click="goto('/register')"
      >
        <span class="block text-bold text-dark">Register</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Bus as VuedalsBus } from 'vuedals';

export default {
  name: 'AuthButtons',
  methods: {
    goto(to) {
      VuedalsBus.$emit('close');
      if (this.$route.path !== to) {
        this.$router.push(to);
      }
    },
  },
};
</script>

<style type="text/css" lang="scss" scoped>
.auth-buttons-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .auth-button {
    width: 100%;
  }
  .auth-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .auth-button + .auth-button {
      margin-left: 5px;
    }
  }
}
</style>
