<template>
  <div class="fallback text-center">
    <div class="fallback-wrapper">
      <div class="text-bold large-font">SORRY!</div>
      <a href="https://google.com" class="rounded-5 medium-font button bg-light-green text-medium text-color-white"
        >Continue to Google</a
      >
      <div>You must be at least 21 years of age to enter this site.</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Fallback',
};
</script>
<style type="text/css" lang="scss" scoped>
.fallback {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  &-wrapper {
    * {
      margin: 30px 0;
      font-weight: 500;
    }
  }
}
</style>
