import { persist, updateMeta } from 'Lib/Utils';
import { Page } from 'Models';

export const PAGES_KEY = 'vaporfly_pages';

export default class Pages {
  #list = new Map();
  #routesMap = new Map();
  constructor(pagesList) {
    this.setPages(pagesList);
  }
  setPages(list) {
    this.#list = new Map(
      list.map(el => {
        const value = el instanceof Page ? el : new Page(el);
        return [value.slug, value];
      })
    );
    this.#routesMap = new Map(
      [...this.#list.values()].map(el => {
        const value = el instanceof Page ? el : new Page(el);
        return [value.path, value];
      })
    );
    persist(PAGES_KEY, this);
  }
  updatePage(slug, data) {
    const value = data instanceof Page ? data : new Page(data);
    this.#list.set(slug, value);
    persist(PAGES_KEY, this);
  }
  /**
   * @param slug
   * @returns {Page}
   */
  get(slug) {
    return this.#list.get(slug);
  }
  /**
   * @param route
   * @returns {Page}
   */
  getByRoute(route) {
    return this.#routesMap.get(route);
  }
  toString() {
    return JSON.stringify([...this.#list.values()]);
  }
  setMetaFor(path) {
    const page = this.getByRoute(path);
    if (page) {
      updateMeta(page.metaInfo);
    }
  }
}
