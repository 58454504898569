import { PACK_N_ACC, VAPORIZERS } from 'Lib/enums/Filters';

export const CAT_VAPORIZERS = 'vaporizers';
export const CAT_PACKAGING = 'packaging-and-accessories';

export default [
  {
    slug: CAT_VAPORIZERS,
    title: 'Vaporizers',
    metaInfo: {
      title: 'Buy Vaporizers Online: 576 High-Quality Items (The Best Offer)',
      description: `Wanna get only high-quality products? It's the right place because of our long-term cooperation with experts who have +10 years of experience.`,
    },
    filters: VAPORIZERS,
    filter: {
      not_types: [66, 135, 136],
    },
  },
  {
    slug: CAT_PACKAGING,
    title: 'Packaging and Accessories',
    metaInfo: {
      title: 'Packaging & Accessories For Vaporizers (The Best Offer 2021)',
      description: `Wanna get high-quality services? It's the right place because of our +10 years of experience. Our main goal is to satisfy any customers. Submit your request 24/7.`,
    },
    filters: PACK_N_ACC,
    filter: {
      not_types: [87, 132, 81, 134, 4, 41],
    },
  },
];
/**
 * Types
 *  4   - Vaporizer
 *  18  - Closed pod system
 *  32  - Open pod system
 *  41  - (deleted)
 *  66  - Bags
 *  81  - FullyDisposablePod
 *  87  - 510 cartridge
 *  131 - Trading
 *  132 - 510 battery
 *  134 - pod battery
 *  135 - Containers
 *  136 - CR packaging
 *  */
