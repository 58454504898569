export default class BaseModel {
  #raw = null;
  constructor(props) {
    Object.assign(this, props || {});
    this.#raw = props;
  }
  get raw() {
    return this.#raw;
  }
}
