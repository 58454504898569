import BaseModel from 'Models/BaseModel';
import dateformat from 'dateformat';

/**
 * @class
 * @prop {number} id
 * @prop {{ constructorPool: ?{ constructors: String[] }, quantity: Number, product: { id, name } }[]} items
 * @prop {string} comment
 * @prop {Object} status
 * @prop {number} status.id
 * @prop {string} status.name
 * @prop {string} createdAt
 */
export default class Order extends BaseModel {
  get date() {
    if (!this.createdAt) {
      return null;
    }
    return new Date(this.createdAt);
  }
  get itemsAmount() {
    return this.items?.length || 0;
  }
  get statusName() {
    return this.status.name;
  }
  getDate(format = 'dddd, mmmm dS, yyyy, HH:MM:ss') {
    if (!this.date) {
      return '';
    }
    return dateformat(this.date, format);
  }
  get productsIds() {
    return this.items.map(({ product }) => product.id);
  }
  get products() {
    return this.items.map(({ product, quantity }) => ({ ...product, quantity }));
  }
  get superId() {
    return `1${this.id.toFixed(0).padStart(5, '0')}`;
  }
  get constructorsEntries() {
    return this.items
      .filter(el => !!el.constructorPool)
      .map(({ product, constructorPool }) => {
        return [product.id, constructorPool.constructors];
      });
  }
}
