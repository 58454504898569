import { render, staticRenderFns } from "./FakeItemTile.vue?vue&type=template&id=f1c384f2&scoped=true&"
import script from "./FakeItemTile.vue?vue&type=script&lang=js&"
export * from "./FakeItemTile.vue?vue&type=script&lang=js&"
import style0 from "./FakeItemTile.vue?vue&type=style&index=0&id=f1c384f2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1c384f2",
  null
  
)

export default component.exports