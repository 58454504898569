import Vue from 'vue';
import Vuex from 'vuex';
import { persist, restore } from 'Lib/Utils';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    status: '',
    token: restore('token', ''),
    user: {},
    search: { filters: {}, total: 0 },
    orderStatuses: [],
    loader: false,
    legal: !!restore('legal', 0),
    cart: new Map(),
    breadcrumbs: [],
  },
  mutations: {
    authSuccess(state, token) {
      state.status = 'success';
      state.token = token;
    },
    authError(state) {
      state.status = 'error';
    },
    logout(state) {
      localStorage.removeItem('token');
      state.status = '';
      state.token = '';
      state.user = {};
      state.search = { filters: {}, total: 0 };
      state.cart.clear();
    },
    search(state, data) {
      state.search.filters = { ...state.search.filters, ...(data || {}) };
    },
    clearSearch(state) {
      state.search.filters = {};
      state.search.total = 0;
    },
    setUser(state, value) {
      state.user = value || {};
    },
    setOrderStatuses(state, value) {
      state.orderStatuses = value || [];
    },
    setLoader(state, value) {
      state.loader = value;
    },
    searchedTotal(state, value) {
      state.search.total = value;
    },
    updateCart(state, { action, id, qty = 100, design = null }) {
      const cart = `cart_${state.user.email}`;
      if (action === 'add') {
        state.cart.set(+id, { id: +id, qty: Math.max(100, qty), design });
        console.log(state.cart.get(+id));
      } else if (action === 'remove') {
        state.cart.delete(+id);
      } else if (action === 'clear') {
        state.cart.clear();
      } else if (action === 'restore') {
        state.cart = new Map(restore(cart, [], true));
      }
      void persist(cart, [...state.cart.entries()], true);
    },
    setBreadcrumbs(state, data) {
      state.breadcrumbs = data;
    },
    setLegal(state, value) {
      persist('legal', +value);
      state.legal = value;
    },
  },
  actions: {
    login({ commit }, { success, token, user }) {
      if (success) {
        commit('authSuccess', persist('token', token));
        commit('setUser', user);
        setTimeout(() => {
          commit('updateCart', { action: 'restore' });
        });
      } else {
        commit('authError');
      }
    },
    logout({ commit }) {
      commit('logout');
    },
    quickSearch({ commit }, value) {
      commit('search', { name: value });
    },
    setUser({ commit }, value) {
      commit('setUser', value);
      commit('updateCart', { action: 'restore' });
    },
    setOrderStatuses({ commit }, value) {
      commit('setOrderStatuses', value);
    },
    loader({ commit }, value) {
      commit('setLoader', !!value);
    },
    search({ commit }, data) {
      commit('search', data);
    },
    clearSearch({ commit }) {
      commit('clearSearch');
    },
    searchedTotal({ commit }, value) {
      commit('searchedTotal', value);
    },
    cartAdd({ commit }, { id, qty = 100, design = null } = {}) {
      commit('updateCart', { action: 'add', id, qty, design });
    },
    cartRemove({ commit }, id) {
      commit('updateCart', { action: 'remove', id });
    },
    cartClear({ commit }) {
      commit('updateCart', { action: 'clear' });
    },
    setBreadcrumbs({ commit }, data) {
      commit('setBreadcrumbs', data);
    },
    setLegal({ commit }, value) {
      commit('setLegal', value);
    },
  },
  modules: {},
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    quickSearch: state => state.search.filters.name || '',
    search: state => ({ ...state.search.filters }),
    isLoading: state => state.loader,
    userData: state => {
      const { name, username, email, registerDate, lastVisitDate, phone, company } = state.user;
      return { name, username, email, registerDate, lastVisitDate, phone, company };
    },
    searchedTotal: state => state.search.total,
    showAgeConfirmation: state => {
      return !state.token && !state.legal;
    },
  },
});
