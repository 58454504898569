<template>
  <div class="footer container-fluid p-tb-3 bg-gray">
    <div class="container">
      <div class="nav row footer-nav bottom-lg p-tb-2">
        <div class="col-lg-2 col-xs-12">
          <router-link to="/" class="flex p-r-3 p-xs-0">
            <img
              class="m-xs-auto footer-logo"
              src="../assets/images/logo.svg"
              alt="vaporfly logo"
              width="180"
              height="30"
            />
          </router-link>
        </div>
        <div class="col-lg-2 col-xs-12">
          <SocialLinks />
        </div>
        <div class="col-lg-8 col-xs-12">
          <div class="footer-nav-items">
            <template v-for="({ to, title, tag, ...params }, i) in links">
              <component v-if="tag" :is="tag" class="p-r-2 text-bold" v-bind="params" :key="i">{{ title }}</component>
              <router-link v-else :to="to" class="p-r-2 text-bold" active-class="link-active" v-text="title" :key="i" />
            </template>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-8 col-xs-12">
          <p class="text-medium text-dark p-tb-1 text-center-xs">
            The easiest way to create and sell vape products online under your brand name.
          </p>
        </div>
        <div class="col-lg-4 col-xs-8 m-xs-auto" />
      </div>
    </div>
  </div>
</template>

<script>
import SocialLinks from 'Components/SocialLinks';

export default {
  name: 'Footer',
  components: { SocialLinks },
  props: {
    links: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style type="text/css" lang="scss">
.footer {
  margin: 0 !important;

  .footer-nav div {
    align-self: center;
  }

  .footer-nav-items {
    position: relative;
    min-height: 30px;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    a {
      margin: 10px 0;
    }
  }
}
@media only screen and (max-width: 640px) {
  .footer-nav-items {
    flex-direction: column !important;
    height: auto !important;
  }
}
</style>
