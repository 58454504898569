import BaseModel from 'Models/BaseModel';
import Img from 'Models/Image';
import { TYPE_IN_STOCK, TYPE_SAMPLES_IN_STOCK } from 'Lib/enums/Types';
import Categories from 'Lib/enums/Categories';

/**
 * @prop {number} id
 * @prop {string} name
 * @prop {string} description
 * @prop {object} company
 * @prop {object} brand
 * @prop {array} features
 * @prop {array} types
 * @prop {string} specification
 * @prop {number} tradingPrice
 * @prop {number} retailPrice
 * @prop {object} defaultImage
 * @prop {number} moq
 * @prop {string} slug
 * @prop {array} constructorImages
 * @prop {array} templates
 * @prop {Object} seoData
 * @prop {string} seoData.description
 * @prop {string} seoData.heading
 * @prop {string} seoData.title
 */
export default class Product extends BaseModel {
  #img;
  get image() {
    if (this.#img !== void 0) {
      return this.#img;
    }
    let img;
    if ('defaultImage' in this) {
      img = this.defaultImage;
    } else if ('images' in this) {
      img = this.images.sort((a, b) => b.default - a.default)[0];
    }
    this.#img = img ? new Img(img) : null;
    return this.#img;
  }
  get thumb() {
    return this.image.thumb;
  }
  get title() {
    return this.name;
  }
  get price() {
    return this.tradingPrice;
  }
  get minimalQty() {
    return this.moq || 100;
  }
  get companyName() {
    return this.company ? this.company.name : '';
  }
  get brandTitle() {
    return this.brand ? this.brand.name : '';
  }
  get inStock() {
    return this.types.some(el => el.id === TYPE_IN_STOCK);
  }
  get samplesInStock() {
    return this.types.some(el => el.id === TYPE_SAMPLES_IN_STOCK);
  }
  get customizable() {
    return !!this.constructorImages?.length;
  }
  get templates() {
    return (this.constructorImages || []).map(tpl => {
      const img = new Img({
        url: tpl.image,
        thumbnailUrl: tpl.thumb,
      });
      return { ...tpl, image: img.src, thumb: img.thumb };
    });
  }

  get category() {
    const [cat] = Categories.filter(({ filter }) => {
      return !this.types.some(({ id }) => filter.not_types.includes(id));
    });
    return cat?.slug;
  }
}
