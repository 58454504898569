<template>
  <div class="container-fluid" v-if="show">
    <div class="container p-t-1">
      <div class="breadcrumbs">
        <div class="breadcrumb-item">
          <router-link to="/">Home</router-link>
        </div>
        <template v-for="(item, i) in breadcrumbs">
          <div class="breadcrumb-item" :key="i">
            <router-link v-if="i < breadcrumbs.length - 1" :to="item.to">{{ item.title }}</router-link>
            <span v-else>{{ item.title }}</span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { MICRO_BREADCRUMBS, removeMicroMarkup, setMicroMarkup } from 'Lib/MicroMarkup';

export default {
  name: 'Breadcrumbs',
  computed: {
    show() {
      return this.breadcrumbs.length && this.$route.path !== '/';
    },
  },
  data() {
    return {
      breadcrumbs: [],
    };
  },
  watch: {
    show(state) {
      if (!state) {
        removeMicroMarkup(MICRO_BREADCRUMBS);
      }
    },
  },
  methods: {
    setBreadcrumbs(breadcrumbs) {
      this.breadcrumbs = breadcrumbs;
      setMicroMarkup(MICRO_BREADCRUMBS, breadcrumbs);
    },
  },
  beforeMount() {
    this.unsubBreadcrumbs = this.$store.subscribe(mutation => {
      if (mutation.type === 'setBreadcrumbs') {
        this.setBreadcrumbs(this.$store.state.breadcrumbs);
      }
    });
    this.setBreadcrumbs(this.$store.state.breadcrumbs);
  },
  beforeDestroy() {
    this.unsubBreadcrumbs();
    removeMicroMarkup(MICRO_BREADCRUMBS);
  },
};
</script>

<style type="text/css" lang="scss">
.breadcrumbs {
  display: flex;
  flex-direction: row;
  font-weight: bolder;

  .breadcrumb-item {
    &:not(:last-child):after {
      content: '>';
      margin: 0 10px;
    }
    a:hover {
      color: #96c22e;
    }
  }
}
</style>
