<template>
  <header>
    <div class="container-fluid">
      <div class="container">
        <div class="row p-tb-2 middle-lg xs-p-tb-05">
          <div class="col-md-2 col-xs-6 col-sm-3">
            <router-link to="/" class="flex p-r-1">
              <img src="../assets/images/logo.svg" alt="vaporfly" width="180" height="30" />
            </router-link>
          </div>
          <div class="col-md-1 hide-xs" />
          <div class="col-md-9 col-xs-6 col-sm-9 user-menu " style="margin-left: auto">
            <div class="block">
              <div class="row">
                <template v-for="(item, i) in menuItems">
                  <div class="col-md-3 col-xs-3" :key="i" :class="{ 'ml-auto': i === 0 }">
                    <template v-if="item.content">
                      <component :is="item.content.component" :class="item.classes" />
                    </template>
                    <template v-else-if="item.to">
                      <router-link :to="item.to" class="block icon-left-medium user-menu-item" :class="item.classes">
                        <span class="block text-bold text-dark title">{{ item.title }}</span>
                        <span v-if="item.subtitle" class="block text-color-light subtitle">{{ item.subtitle }}</span>
                      </router-link>
                    </template>
                    <template v-else>
                      <div
                        class="block icon-left-medium user-menu-item"
                        :class="item.classes"
                        style="cursor: pointer"
                        @click="item.onClick"
                      >
                        <span class="block text-bold text-dark title">{{ item.title }}</span>
                        <span v-if="item.subtitle" class="block text-color-light subtitle">{{ item.subtitle }}</span>
                      </div>
                    </template>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Navigation :links="links" />
  </header>
</template>

<script>
import Navigation from 'Components/Navigation';
import CartBlamba from 'Components/CartBlamba';
import { Bus as VuedalsBus } from 'vuedals';
import AuthButtons from 'Components/AuthButtons';
import ModalHeader from 'Components/ModalHeader';

export default {
  name: 'Header',
  props: {
    links: {
      type: Array,
      default: () => [],
    },
  },
  components: { CartBlamba, Navigation },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    menuItems() {
      const publicLinks = [
        {
          to: '/help',
          title: 'Help is here',
          subtitle: 'Talk with an Expert',
          classes: 'help-icon',
        },
        {
          title: 'Sign up / Log in',
          subtitle: '',
          classes: 'sign-in-icon sign-in',
          onClick: this.showAuthButtonsPopup,
        },
      ];
      const privateLinks = [
        {
          to: '/help',
          title: 'Help is here',
          subtitle: 'Talk with an Expert',
          classes: 'help-icon',
        },
        {
          to: '/orders',
          title: 'My Orders',
          subtitle: 'Orders history',
          classes: 'list-icon',
        },
        {
          to: '/cart',
          classes: 'cart-blamba',
          content: {
            component: CartBlamba,
          },
        },
        {
          to: '/profile',
          title: 'My account',
          subtitle: 'Office and settings',
          classes: 'user-icon',
        },
      ];

      return this.isLoggedIn ? privateLinks : publicLinks;
    },
  },
  methods: {
    showAuthButtonsPopup() {
      VuedalsBus.$emit('new', {
        name: 'auth-popup',
        component: AuthButtons,
        props: {},
        size: 'xs',
        escapable: true,
        closeOnBackdrop: true,
        dismissable: true,
        header: {
          component: ModalHeader,
          props: {
            size: 'xs',
          },
        },
      });
    },
  },
};
</script>

<style type="text/css" lang="scss">
.ml-auto {
  margin-left: auto !important;
}
.sign-in {
  min-height: 32px;
  display: flex !important;
  align-items: center;
}
</style>
