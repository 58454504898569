<template>
  <div class="mb-3 text-center" style="width: 100%">
    <img
      class=""
      src="../assets/images/logo.svg"
      alt="vaporfly logo"
      :height="imgHeight"
      :width="imgHeight * aspectRatio"
    />
  </div>
</template>

<script>
export default {
  name: 'ModalHeader',
  props: {
    size: {
      type: String,
      default: () => 'md',
    },
  },
  computed: {
    imgHeight() {
      return { xs: 30, sm: 40, md: 50 }[this.size];
    },
    aspectRatio() {
      return 6;
    },
  },
};
</script>
