<template>
  <div class="container-fluid p-tb-1 nav-container">
    <input type="checkbox" id="nav-menu" class="toggle" v-model="menu" />
    <label for="nav-menu" class="popup-label">
      <svg class="close" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 329.26933 329" height="25px" width="15px">
        <path
          d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"
        />
      </svg>
      <svg
        class="open"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 384.97 384.97"
        style="enable-background:new 0 0 384.97 384.97; width: 25px"
        xml:space="preserve"
      >
        <g>
          <g id="Menu_1_">
            <path
              d="M12.03,120.303h360.909c6.641,0,12.03-5.39,12.03-12.03c0-6.641-5.39-12.03-12.03-12.03H12.03    c-6.641,0-12.03,5.39-12.03,12.03C0,114.913,5.39,120.303,12.03,120.303z"
            />
            <path
              d="M372.939,180.455H12.03c-6.641,0-12.03,5.39-12.03,12.03s5.39,12.03,12.03,12.03h360.909c6.641,0,12.03-5.39,12.03-12.03    S379.58,180.455,372.939,180.455z"
            />
            <path
              d="M372.939,264.667H132.333c-6.641,0-12.03,5.39-12.03,12.03c0,6.641,5.39,12.03,12.03,12.03h240.606    c6.641,0,12.03-5.39,12.03-12.03C384.97,270.056,379.58,264.667,372.939,264.667z"
            />
          </g>
        </g>
      </svg>
      <span>Browse</span>
    </label>
    <div class="container nav main-nav">
      <template v-for="({ title, to, tag, ...params }, i) in links">
        <div :key="i" class="text-bold nav-link" style="display: inline-block" @click="toggleMenu">
          <component v-if="tag" :is="tag" v-bind="params">{{ title }}</component>
          <router-link :to="to" v-else active-class="link-active" v-text="title" v-bind="params" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Navigation',
  props: {
    links: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    toggleMenu() {
      this.menu = false;
    },
  },
  data() {
    return {
      menu: false,
    };
  },
};
</script>
<style type="text/css" lang="scss">
.link-active {
  color: #96c22e;
}
</style>
