<template>
  <div class="social-links">
    <template v-for="(item, i) in social">
      <a
        class="social-link"
        :class="item.classes"
        :href="item.href"
        :title="item.title"
        target="_blank"
        rel="noopener nofollow"
        :key="i"
      >
        <img :src="item.icon" :alt="`${item.title} icon`" class="m-xs-auto" height="30" width="30" />
      </a>
    </template>
  </div>
</template>

<script>
export default {
  name: 'SocialLinks',
  computed: {
    social() {
      return [
        {
          href: 'https://www.instagram.com/vaporfly_com/',
          title: 'Instagram official',
          icon: '/img/social-icons/instagram.svg',
          classes: 'link-ig',
        },
        {
          href: 'https://twitter.com/vaporfly_/',
          title: 'Twitter official',
          icon: '/img/social-icons/twitter.svg',
          classes: 'link-tw',
        },
        {
          href: 'https://www.linkedin.com/company/vaporfly/',
          title: 'LinkedIn official',
          icon: '/img/social-icons/linkedin.svg',
          classes: 'link-li',
        },
        {
          href: 'https://www.facebook.com/VaporFly-100566085454486',
          title: 'Facebook official',
          icon: '/img/social-icons/facebook.svg',
          classes: 'link-fb',
        },
      ];
    },
  },
};
</script>

<style type="text/css" lang="scss" scoped>
.social-links {
  height: 30px;

  .social-link {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    padding: 0 5px;
  }
}
</style>
