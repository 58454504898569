<!--suppress HtmlUnknownTarget -->
<template>
  <div class="product item-tile fake-tile">
    <div class="p-10px rounded-5 bordered-light fake-inner">
      <div class="img-holder flex text-center">
        <div class="flex w-100">
          <img class="m-auto" src="/img/logo-small.svg" alt="placeholder image" />
        </div>
      </div>
      <div class="fake-title-lines" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ItemTile',
};
</script>

<style type="text/css" lang="scss" scoped>
.item-tile.fake-tile {
  position: relative;
  overflow: hidden;
  .fake-inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    .fake-title-lines {
      min-height: 2rem;
      margin: 14px 0;
    }
  }
}
</style>
