import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home';
import store from '../store';
import { isJWTExpired, updateMeta } from 'Lib/Utils';

Vue.use(VueRouter);

export const DEF_META_TITLE = 'Buy Vaporizers & Oil Cartridges Online (The Best Offer 2021)';
export const DEF_META_DESC = `Wanna get high-quality products (The catalog consists of over 500 items)? It's the right place because of our long-term cooperation with leading Chinese manufacturers.`;

const getProductItemRoute = (path, name) => {
  return {
    path,
    name,
    component: () => import(/* webpackChunkName: "product" */ '../views/Product'),
    meta: {
      isPrivate: false,
      metaInfo: {
        templates: {
          title: 'Buy {title} Online (The Best Offer 2021)',
          description: `Wanna buy {title} Online? Visit our online shop to get the possible online price, high-quality products. We win if you win.`,
        },
      },
    },
  };
};

const getProductListRoute = (path, name) => {
  return {
    path,
    name,
    component: () => import(/* webpackChunkName: "products" */ '../views/Products'),
    meta: {
      isPrivate: false,
    },
  };
};

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      breadcrumbs: {
        title: 'Home',
      },
      metaInfo: {
        title: DEF_META_TITLE,
        description: DEF_META_DESC,
      },
    },
  },
  getProductListRoute(
    '/:cat(vaporizers)/:subcat(510-cartridges|510-batteries|disposable-pod-systems|disposable-pens|pod-systems)?'
  ),
  getProductListRoute('/:cat(packaging-and-accessories)/:subcat(bags|containers)?'),
  getProductItemRoute(
    '/(packaging-and-accessories|vaporizers)/(bags|containers|510-cartridges|510-batteries|disposable-pod-systems|disposable-pens|pod-systems)/:slug'
  ),
  getProductItemRoute('/(packaging-and-accessories|vaporizers)/:slug'),
  getProductItemRoute('/product/:slug'),
  {
    path: '/constructor/:slug',
    name: 'Constructor',
    components: {
      customizer: () => import(/* webpackChunkName: "customizer" */ '../views/Constructor'),
    },
    props: true,
    meta: {
      isPrivate: true,
      metaInfo: {
        title: 'Constructor | VaporFly',
      },
    },
  },
  {
    path: '/customization',
    name: 'Customization',
    component: () => import(/* webpackChunkName: "customization" */ '../views/Customization'),
  },
  {
    path: '/order/:slug',
    name: 'ProductOrder',
    component: () => import(/* webpackChunkName: "product-order" */ '../views/ProductOrder'),
    props: true,
    meta: {
      isPrivate: true,
      metaInfo: {
        title: 'Order Product | VaporFly',
      },
    },
  },
  {
    path: '/orders',
    name: 'Orders',
    component: () => import(/* webpackChunkName: "orders" */ '../views/Orders'),
    meta: {
      isPrivate: true,
      metaInfo: {
        title: 'Orders | VaporFly',
      },
    },
  },
  {
    path: '/orders/:id',
    name: 'OrderDetails',
    component: () => import(/* webpackChunkName: "order-details" */ '../views/OrderDetails'),
    meta: {
      isPrivate: true,
      metaInfo: {
        templates: {
          title: 'Order #{id} | VaporFly',
        },
      },
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Backoffice'),
    meta: {
      isPrivate: true,
      metaInfo: {
        title: 'Profile | VaporFly',
      },
    },
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () => import(/* webpackChunkName: "cart" */ '../views/Cart'),
    meta: {
      isPrivate: true,
      metaInfo: {
        title: 'Cart | VaporFly',
      },
    },
  },
  {
    path: '/how-it-works',
    name: 'HowItWorks',
    component: () => import(/* webpackChunkName: "how-it-works" */ '../views/HowItWorks'),
    meta: {
      metaInfo: {
        title: 'How It Works | Vaporfly',
        description: `Our simple process includes the following: place your order, register, and specify details. After production, you'll receive products.`,
      },
    },
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: () => import(/* webpackChunkName: "about-us" */ '../views/AboutUs'),
    meta: {
      metaInfo: {
        title: 'About Us | VaporFly',
        description: `Vaporfly is a California-based company with +10 years of experience in the vaping industry, engaged in the wholesale and white labeling of cannabis devices.`,
      },
    },
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: () => import(/* webpackChunkName: "contact-us" */ '../views/ContactUs'),
    meta: {
      metaInfo: {
        title: 'Contact Us | VaporFly',
        description: DEF_META_DESC,
      },
    },
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import(/* webpackChunkName: "faq" */ '../views/FAQ'),
  },
  {
    path: '/our-services',
    name: 'OurServices',
    component: () => import(/* webpackChunkName: "our-services" */ '../views/OurServices'),
    meta: {
      metaInfo: {
        title: 'Get Help For Cannabis Enterprises (+10 Years of Experience)',
        description: `A highly qualified international team of engineers, QA agents, and logistic specialists to provide optimal solutions for cannabis enterprises.`,
      },
    },
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackChunkName: "privacy-policy" */ '../views/PrivacyPolicy'),
    metaInfo: {
      title: 'Privacy Policy | VaporFly',
    },
  },
  {
    path: '/help',
    name: 'Help',
    component: () => import(/* webpackChunkName: "help" */ '../views/Help'),
    meta: {
      metaInfo: {
        title: 'Help | VaporFly',
        description: `Reach out to us with your suitable methods: email, Instagram, chat, phone, etc. We're online 24/7. Get the response asap. Your satisfaction is our goal.`,
      },
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ 'Views/Auth'),
    props: {
      form: 'login',
    },
    meta: {
      metaInfo: {
        title: 'Log In | VaporFly',
      },
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ 'Views/Auth'),
    props: {
      form: 'register',
    },
    meta: {
      metaInfo: {
        title: 'Register | VaporFly',
      },
    },
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound'),
  },
];

// console.log(routes.filter(el => !el?.meta?.isPrivate));

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const { metaInfo } = to.meta;
  if (metaInfo && ('title' in metaInfo || 'description' in metaInfo)) {
    updateMeta(metaInfo);
  }
  if (to.matched.some(record => record.meta.isPrivate)) {
    if (store.getters.isLoggedIn && !isJWTExpired(store.state.token)) {
      next();
      return;
    }
    next({ path: '/login', query: { redirect: to.fullPath } });
    store.dispatch('logout');
  } else {
    next();
  }
});

// router.afterEach(() => {
//   window.scrollTo({ top: 0, behavior: 'smooth' });
// });
// router.afterEach(to => {
//   const { metaInfo } = to.meta;
//   if (metaInfo && ('title' in metaInfo || 'description' in metaInfo)) {
//     return;
//   }
//   if (metaInfo?.templates) {
//     return;
//   }
//   updateMeta({ title: DEF_META_TITLE, description: DEF_META_DESC });
// });

export default router;

// const urls = [];
// for (const { path } of routes) {
//   if (path === '*' || /:/.test(path)) {
//     continue;
//   }
//   urls.push(`\t<url>
// \t\t<loc>https://vaporfly.com${path}</loc>
// \t\t<lastmod>2021-02-25</lastmod>
// \t\t<changefreq>monthly</changefreq>
// \t\t<priority>0.8</priority>
// \t</url>`);
// }
// const sitemap = `<?xml version="1.0" encoding="UTF-8"?>
// <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
//    ${urls.join('\n')}
// </urlset> `;
// console.log(sitemap);
