<template>
  <div class="container slider-wrapper" ref="slider">
    <div class="mobile-counter">{{ pluralize('Product', slides) }} {{ productCounter }} of 20</div>
    <div class="slider-nav slider-prev hide-xs" v-if="isPrevEnabled" @click.stop="slideUp(-1)">&#x2039;</div>
    <div class="row" v-if="!collection || loading">
      <FakeItemTile class="col-lg-3 col-xs-12" :class="{ 'mb-10px': slides > 4 }" v-for="i in slides" :key="i" />
    </div>
    <div v-else class="row">
      <template v-for="(item, i) in products">
        <ItemTile :item="item" show-add-to-cart :key="i" class="col-lg-3 col-xs-12" :loading="loading" />
      </template>
    </div>
    <div
      class="slider-nav slider-next hide-xs"
      :class="{ 'mb-10px': slides > 4 }"
      v-if="isNextEnabled"
      @click.stop="slideUp(1)"
    >
      &#x203A;
    </div>
  </div>
</template>

<script>
import ItemTile from 'Components/ItemTile';
import FakeItemTile from 'Components/FakeItemTile';
import { pluralize } from 'Lib/Utils';

const LG_SLIDES = 8;
const XS_SLIDES = 1;

export default {
  name: 'ProductSlider',
  props: {
    filter: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { ItemTile, FakeItemTile },
  methods: {
    pluralize,
    load() {
      this.loading = true;
      this.$api
        .getProductsCollection(
          {
            ...(this.filter || {}),
            itemsPerPage: this.itemsPerPage,
            page: 1,
            properties: ['id', 'name', 'defaultImage', 'tradingPrice', 'images', 'slug', 'types', 'moq'],
          },
          !this.isLoggedIn
        )
        .then(collection => {
          this.collection = collection;
          this.loading = false;
        })
        .catch(this.onError);
    },
    loadMore() {
      this.loading = true;
      this.collection
        .loadNextPage()
        .then(collection => {
          this.collectionPage = collection.currentPage;
          this.$slugger.batchUpdate(collection.items.map(item => [item.slug, item.id]));
          this.loading = false;
        })
        .catch(this.onError);
    },
    onError() {
      this.loading = false;
    },
    slideUp(delta) {
      if ((delta > 0 && !this.isNextEnabled) || (delta < 0 && !this.isPrevEnabled)) {
        return;
      }
      this.offset = this.offset + this.slides * delta;
      if (this.collection.loadedAmount - (this.offset + this.slides) <= this.slides && this.canLoadMore) {
        this.loadMore();
      }
    },
    onResize() {
      if (window.innerWidth <= 640) {
        this.swipe = true;
        this.slides = XS_SLIDES;
      } else {
        this.swipe = false;
        this.slides = LG_SLIDES;
      }
      this.offset = 0;
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    canLoadMore() {
      return this.collection && this.collection.size < this.collection.total;
    },
    products() {
      return this.collection?.getItems(this.offset, this.slides) || [];
    },
    isPrevEnabled() {
      return this.offset > 0;
    },
    isNextEnabled() {
      return this.offset + this.slides < (this.collection?.total || 0);
    },
    productCounter() {
      const start = this.offset + 1;
      if (this.slides === XS_SLIDES) {
        return start;
      } else {
        return `${start} - ${this.offset + this.slides}`;
      }
    },
  },
  data() {
    return {
      swipe: false,
      loading: false,
      collection: null,
      itemsPerPage: 12,
      collectionPage: 1,
      offset: 0,
      slides: LG_SLIDES,
    };
  },
  mounted() {
    setTimeout(() => {
      if (!this.collection) {
        this.load();
      }
    });
    this.onResize();
    window.addEventListener('resize', this.onResize);
    let start;
    this.$refs.slider.addEventListener(
      'touchstart',
      e => {
        if (this.swipe && e.touches.length === 1) {
          const [{ clientX }] = e.touches;
          start = clientX;
        }
      },
      false
    );
    this.$refs.slider.addEventListener(
      'touchend',
      e => {
        if (this.swipe && e.changedTouches.length === 1) {
          const [{ clientX }] = e.changedTouches;
          if (Math.abs(start - clientX) > 80) {
            this.slideUp(start - clientX > 0 ? 1 : -1);
          }
        }
        start = void 0;
      },
      false
    );
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style type="text/css" lang="scss">
.mb-10px {
  margin-bottom: 10px !important;
}
.slider-wrapper {
  position: relative;
  overflow: visible !important;
  user-select: none;

  .mobile-counter {
    text-align: center;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .slider-nav {
    position: absolute;
    font-size: 10em;
    color: #494b4d;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    top: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    transition: color 0.1s linear;

    &:hover {
      color: #96c22e;
    }

    &.slider-prev {
      left: -64px;

      &:hover {
        background-image: linear-gradient(-90deg, #ffffff, #ababab34);
      }
    }

    &.slider-next {
      right: -64px;

      &:hover {
        background-image: linear-gradient(90deg, #ffffff, #ababab34);
      }
    }
  }

  @media only screen and (max-width: 640px) {
    .mobile-counter {
      display: block;
    }
  }
}
</style>
