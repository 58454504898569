<template>
  <div>
    <div class="container-fluid main-section home-tiles">
      <div class="row">
        <router-link to="/vaporizers" class="col-xs-12 col-sm-6 col-md-4 p-0 head-link-block">
          <img src="../assets/images/hero/vaporizer3.jpg" width="1000" alt="hero" />
          <div class="pipka-underlay">
            <span>Vaporizers</span>
          </div>
        </router-link>
        <router-link to="/packaging-and-accessories" class="col-xs-12 col-sm-6 col-md-4 p-0 head-link-block">
          <img src="../assets/images/hero/packaging.jpg" width="1000" alt="hero" />
          <div class="pipka-underlay">
            <span>Packaging</span>
          </div>
        </router-link>
        <router-link to="/customization" class="col-xs-12 col-sm-6 col-md-4 p-0 head-link-block">
          <img src="../assets/images/hero/customisation.jpg" width="1000" alt="hero" />
          <div class="pipka-underlay">
            <span>Customization</span>
          </div>
        </router-link>
        <router-link to="/faq" class="col-xs-12 col-sm-6 col-md-4 p-0 head-link-block">
          <img src="../assets/images/hero/faq.jpg" width="1000" alt="hero" />
          <div class="pipka-underlay">
            <span>F.A.Q.</span>
          </div>
        </router-link>
        <router-link to="/our-services" class="col-xs-12 col-sm-6 col-md-4 p-0 head-link-block">
          <img src="../assets/images/hero/service.jpg" width="1000" alt="hero" />
          <div class="pipka-underlay">
            <span>Services</span>
          </div>
        </router-link>
        <a href="https://vaporfly.com/blog" class="col-xs-12 col-sm-6 col-md-4 p-0 head-link-block">
          <img src="../assets/images/hero/blog.jpg" width="1000" alt="hero" />
          <div class="pipka-underlay">
            <span>Blog</span>
          </div>
        </a>
      </div>
    </div>
    <div class="container-fluid" v-if="showFeatured">
      <div class="container-fluid bg-gray">
        <h2 class="text-center">Featured products</h2>
      </div>
      <ProductSlider :filter="listFilter" class="p-tb-1" />
    </div>
  </div>
</template>

<script>
import { TYPE_IN_STOCK, TYPE_SAMPLES_IN_STOCK } from 'Lib/enums/Types';
import ProductSlider from 'Components/ProductSlider';
import { MICRO_BREADCRUMBS, MICRO_LOCAL_BUSINESS, removeMicroMarkup, setMicroMarkup } from 'Lib/MicroMarkup';
import MetaMixin from 'Lib/mixins/MetaMixin';

const howItWorksItems = () => [
  {
    title: 'PRODUCT DATABASE',
    image: 'database.jpg',
  },
  {
    title: 'SELECT PRODUCT',
    image: 'select.jpg',
  },
  {
    title: 'CUSTOMIZE',
    image: 'customize.jpg',
  },
  {
    title: 'PRODUCTION',
    image: 'production.jpg',
  },
  {
    title: 'QUALITY CONTROL',
    image: 'control.jpg',
  },
  {
    title: 'HEAVY METAL TEST',
    image: 'metal.jpg',
  },
  {
    title: 'PACKAGING',
    image: 'pack.jpg',
  },
  {
    title: 'SHIPPING',
    image: 'ship.jpg',
  },
];

export default {
  name: 'Home',
  mixins: [MetaMixin],
  components: { ProductSlider },
  data() {
    return {
      total: 0,
      howItWorksItems: howItWorksItems(),
      filter: {
        'types.id': [TYPE_IN_STOCK, TYPE_SAMPLES_IN_STOCK],
      },
      showFeatured: false,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    listFilter() {
      return {
        ...this.filter,
        itemsPerPage: 8,
      };
    },
  },
  methods: {
    countFeaturedProducts() {
      this.$api
        .getProductsCollection({ ...this.filter, itemsPerPage: 0 }, !this.isLoggedIn)
        .then(coll => {
          this.showFeatured = coll.total > 0;
        })
        .catch(console.error);
    },
  },
  mounted() {
    this.$api.getProductsAmount().then(total => {
      this.total = total;
    });
  },
  created() {
    this.countFeaturedProducts();
  },
  beforeMount() {
    this.$store.dispatch('setBreadcrumbs', []);
    setMicroMarkup(MICRO_LOCAL_BUSINESS);
    removeMicroMarkup(MICRO_BREADCRUMBS);
  },
  beforeDestroy() {
    removeMicroMarkup(MICRO_LOCAL_BUSINESS);
  },
};
</script>
