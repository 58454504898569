<template>
  <router-link to="/cart" class="block icon-left-medium cart-icon user-menu-item">
    <span class="block text-bold text-dark">My Cart</span>
    <span v-if="itemsAmount > 0" class="block text-color-light in-cart-items">
      <strong>{{ itemsAmount }}</strong> <span>{{ pluralize('item', itemsAmount) }} in cart</span>
    </span>
    <span v-else class="block text-color-light">Cart is empty</span>
  </router-link>
</template>

<script>
import { pluralize } from 'Lib/Utils';

export default {
  name: 'CartBlamba',
  methods: {
    pluralize,
  },
  data() {
    return {
      itemsAmount: 0,
    };
  },
  created() {
    this.unsubCart = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'updateCart') {
        this.itemsAmount = state.cart.size;
      }
    });
    this.itemsAmount = this.$store.state.cart.size;
  },
  beforeDestroy() {
    this.unsubCart();
  },
};
</script>
