import BaseModel from 'Models/BaseModel';

/**
 * @prop {string} slug
 * @prop {string} path
 * @prop {string} title
 * @prop {string} description
 * @prop {string} heading
 * @prop {string} text
 */
export default class Page extends BaseModel {
  get metaInfo() {
    const { title, description } = this;
    return { title, description };
  }
}
