function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"footer container-fluid p-tb-3 bg-gray"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"nav row footer-nav bottom-lg p-tb-2"},[_c('div',{staticClass:"col-lg-2 col-xs-12"},[_c('router-link',{staticClass:"flex p-r-3 p-xs-0",attrs:{"to":"/"}},[_c('img',{staticClass:"m-xs-auto footer-logo",attrs:{"src":require("../assets/images/logo.svg"),"alt":"vaporfly logo","width":"180","height":"30"}})])],1),_c('div',{staticClass:"col-lg-2 col-xs-12"},[_c('SocialLinks')],1),_c('div',{staticClass:"col-lg-8 col-xs-12"},[_c('div',{staticClass:"footer-nav-items"},[_vm._l((_vm.links),function(ref,i){
var to = ref.to;
var title = ref.title;
var tag = ref.tag;
var rest = objectWithoutProperties( ref, ["to", "title", "tag"] );
var params = rest;
return [(tag)?_c(tag,_vm._b({key:i,tag:"component",staticClass:"p-r-2 text-bold"},'component',params,false),[_vm._v(_vm._s(title))]):_c('router-link',{key:i,staticClass:"p-r-2 text-bold",attrs:{"to":to,"active-class":"link-active"},domProps:{"textContent":_vm._s(title)}})]})],2)])]),_vm._m(0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-8 col-xs-12"},[_c('p',{staticClass:"text-medium text-dark p-tb-1 text-center-xs"},[_vm._v(" The easiest way to create and sell vape products online under your brand name. ")])]),_c('div',{staticClass:"col-lg-4 col-xs-8 m-xs-auto"})])}]

export { render, staticRenderFns }