module.exports = {
  origin: process.env.NODE_ENV === 'production' ? 'https://vaporfly.com/' : 'http://vaporfly.dev.arkotik.com.ua/',
  api: {
    host: 'https://wims2.winglegroup.com',
    suffix: '.jsonld',
    auth: '/login_check',
    register: '/api/users/register_shop',
  },
  recaptcha: {
    siteKey: '6LdT4-wZAAAAAJNJeqOXBzjzJIFOx2eYDr4d4RQF',
  },
};
