<!--suppress HtmlUnknownTarget -->
<template>
  <div class="product item-tile">
    <div v-if="!!ribbon" class="ribbon" :class="ribbon" />
    <div class="buttons-handler" v-if="showAddToCart && isLoggedIn">
      <div v-if="isInCart" class="in-cart-mark" title="This item already in your  cart" />
      <div v-if="isInCart" class="hidden-button add-to-cart in-cart" @click="removeFromCart">Remove from cart</div>
      <div v-else class="hidden-button add-to-cart" @click.stop="addToCart">Add to cart</div>
      <div v-if="item.customizable" class="hidden-button customize" @click.stop="customize">Customize</div>
    </div>
    <router-link :to="`${prefix || item.category}/${slug}`" class="p-10px rounded-5 bordered-light">
      <div class="img-holder flex text-center">
        <div class="flex w-100">
          <img class="m-auto" :src="image || '/img/logo-small.svg'" :alt="item.title" @load="onImageLoad" />
        </div>
      </div>
      <div v-if="loading">
        <p />
        <p />
      </div>
      <div v-else>
        <p class="text-center w-75 m-auto">{{ item.title }}</p>
        <p class="text-center" v-if="item.price">
          <span class="text-color-light-green text-bold">Under my brand </span>
          <span class="text-bold">
            from <span class="price">${{ item.price.toFixed(2) }}</span>
          </span>
        </p>
        <p class="text-center" v-else>
          <span class="text-bold">
            get a quote
          </span>
        </p>
      </div>
    </router-link>
  </div>
</template>

<script>
import Product from 'Models/Product';
import { TYPE_IN_STOCK, TYPE_SAMPLES_IN_STOCK } from 'Lib/enums/Types';

const RIBBON_IN_STOCK = 'in-stock';
const RIBBON_SAMPLES_IN_STOCK = 'samples-in-stock';

export default {
  name: 'ItemTile',
  props: {
    item: Product,
    prefix: {
      type: String,
      // default: () => '/product',
    },
    showAddToCart: {
      type: Boolean,
      default: () => false,
    },
    loading: Boolean,
  },
  data() {
    return {
      isInCart: false,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    image() {
      return this.loading ? null : this.item.image;
    },
    ribbon() {
      if (!this?.item?.types?.length) {
        return '';
      }
      for (const type of this.item.types) {
        if (type.id === TYPE_IN_STOCK) {
          return RIBBON_IN_STOCK;
        }
        if (type.id === TYPE_SAMPLES_IN_STOCK) {
          return RIBBON_SAMPLES_IN_STOCK;
        }
      }
      return '';
    },
    slug() {
      if (!this.item.slug) {
        if (this.$slugger.hasId(this.item.id)) {
          return this.$slugger.getSlug(this.item.id);
        }
      }
      return this.item.slug;
    },
  },
  methods: {
    addToCart() {
      this.$store.dispatch('cartAdd', { id: this.item.id, qty: this.item.minimalQty });
    },
    customize() {
      this.$router.push(`/constructor/${this.item.slug}`);
    },
    removeFromCart() {
      this.$store.dispatch('cartRemove', this.item.id);
    },
    onImageLoad(e) {
      e?.path[1]?.style?.setProperty('background-color', 'white');
    },
  },
  watch: {
    slug() {
      this.isInCart = this.$store.state.cart.has(+this.item.id);
    },
  },
  created() {
    this.unsubCart = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'updateCart') {
        this.isInCart = state.cart.has(+this.item.id);
      }
    });
    this.isInCart = this.$store.state.cart.has(+this.item.id);
  },
  beforeDestroy() {
    this.unsubCart();
  },
};
</script>

<style type="text/css" lang="scss" scoped>
.img-holder {
  background-image: url(/img/logo-small.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
}

.item-tile {
  position: relative;
  overflow: hidden;

  .in-cart-mark {
    background-image: url(../assets/images/cart.svg);
    display: none;
    position: absolute;
    width: 32px;
    height: 32px;
    padding: 0;
    margin: 0;
    right: 15px;
    top: 5px;
    background-color: white;
    background-repeat: no-repeat;
    background-size: 75%;
    background-position: center;
    border: 2px solid #96c22e;
    border-radius: 50%;
    z-index: 10;
  }

  .buttons-handler {
    position: absolute;
    top: 10px;
    right: 18px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .hidden-button {
      text-align: center;
      width: max-content;
      padding: 7px 10px;
      border-radius: 5px;
      cursor: pointer;
      display: none;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &.add-to-cart {
        background: #96c22e;
        color: #fff;
        font-weight: 500;

        &.in-cart {
          background-color: #494b4d;
        }
      }

      &.customize {
        background: #fbac45;
        color: #fff;
        font-weight: 500;
      }
    }
  }

  &:hover {
    .hidden-button {
      display: block !important;
    }
  }

  .ribbon {
    --ribbon-size: 130px;
    position: absolute;
    top: 0;
    left: 8px;
    width: var(--ribbon-size);
    height: var(--ribbon-size);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      width: calc(var(--ribbon-size) * 1.5);
      text-align: center;
      font-weight: bold;
      letter-spacing: 1px;
      font-size: 1.3em;
      transform: rotateZ(-45deg) translateY(-50%);
    }

    &.in-stock:before {
      content: 'In Stock';
      background-color: #96c22e;
      color: white;
    }

    &.samples-in-stock:before {
      content: 'Samples In Stock';
      background-color: #fbac45;
      color: white;
      font-size: 1em;
    }

    &.sale:before {
      content: 'Sale';
      background-color: #f30000;
      color: white;
      font-size: 1.3em;
    }
  }
}
</style>
