import { buildURL, createElement } from 'Lib/Utils';

const LOGO_IMAGE = buildURL('/img/logo-small.svg');

export const MICRO_LOCAL_BUSINESS = 'structured-data-local-business';
export const MICRO_BREADCRUMBS = 'structured-data-breadcrumbs';
export const MICRO_PRODUCT = 'structured-data-product';

export function removeMicroMarkup(type) {
  const el = document.getElementById(type);
  if (el) {
    el.remove();
  }
}

export function setMicroMarkup(type, data) {
  const el = getContainer(type);
  switch (type) {
    case MICRO_LOCAL_BUSINESS:
      el.innerHTML = createLocalBusinessMarkup();
      break;
    case MICRO_BREADCRUMBS:
      el.innerHTML = createBreadcrumbsMarkup(data);
      break;
    case MICRO_PRODUCT:
      el.innerHTML = createProductMarkup(data);
      break;
  }
}

function getContainer(type) {
  let el = document.getElementById(type);
  if (!el) {
    el = createElement('script', { type: 'application/ld+json', id: type });
    document.head.appendChild(el);
  }
  return el;
}

function createLocalBusinessMarkup() {
  const _data = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    '@id': 'http://vaporfly.com',
    name: 'VaporFly',
    url: 'https://vaporfly.com',
    logo: LOGO_IMAGE,
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: '+1-530-515-1817',
        email: 'lo.salee@vaporfly.com',
        contactType: 'Account manager',
      },
      {
        '@type': 'ContactPoint',
        telephone: '+1-437-332-0082',
        email: 'valeriia.baliura@vaporfly.com',
        contactType: 'Business Development Manager',
      },
      {
        '@type': 'ContactPoint',
        telephone: '+385-91-605-6633',
        email: 'sales@vaporfly.com',
        contactType: 'customer service',
      },
    ],
    address: {
      '@type': 'PostalAddress',
      streetAddress: '10461 Old Placerville Rd. Suite 150',
      addressLocality: 'Sacramento',
      addressRegion: 'CA',
      postalCode: '95827',
      addressCountry: 'US',
    },
  };
  return JSON.stringify(_data);
}

function createBreadcrumbsMarkup(breadcrumbs) {
  const _data = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbs.map(({ title, to }, i) => {
      return {
        '@type': 'ListItem',
        position: i + 1,
        name: title,
        item: buildURL(to),
      };
    }),
  };
  return JSON.stringify(_data);
}

const AV_IN_STOCK = 'https://schema.org/InStock';
const AV_PRE_ORDER = 'https://schema.org/PreOrder';

/**
 * @param product {Product}
 * @param url {String}
 * @returns {string}
 */
function createProductMarkup({ product, url }) {
  const _data = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: product.title,
    image: product.image?.src || LOGO_IMAGE,
    description: product.description,
    // sku: '0446310786',
    // mpn: '925872',
    brand: {
      '@type': 'Brand',
      name: product.brandTitle,
    },
    // review: {
    //   '@type': 'Review',
    //   reviewRating: {
    //     '@type': 'Rating',
    //     ratingValue: '4',
    //     bestRating: '5',
    //   },
    //   author: {
    //     '@type': 'Person',
    //     name: 'Fred Benson',
    //   },
    // },
    // aggregateRating: {
    //   '@type': 'AggregateRating',
    //   ratingValue: '4.4',
    //   reviewCount: '89',
    // },
    offers: {
      '@type': 'Offer',
      url: buildURL(url),
      priceCurrency: 'USD',
      price: product.price || 0,
      itemCondition: 'https://schema.org/NewCondition',
      availability: product.inStock ? AV_IN_STOCK : AV_PRE_ORDER,
    },
  };
  return JSON.stringify(_data);
}
